<template>
  <v-carousel v-model="model" hide-delimiters="true" height="39vw">
    <v-carousel-item>
      <img class="carousel-img" alt="" src="../assets/imgs/carousel1.jpg" />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data: () => ({
    // imgs: [
    //   "../assets/imgs/carousel1.jpg",
    //   "../assets/imgs/carousel2.jpg",
    // ]
  }),
};
</script>
<style scoped>
.carousel-img {
  height: 43vw;
}
</style>