<template>
  <div
    class="back-to-top-button"
    :class="show ? 'show' : 'hide'"
    @click="toTop"
  >
    <img
      class="back-to-top-img"
      alt="返回顶部"
      src="../assets/icons/arrow-up-bold.svg"
    />
  </div>
</template>

<script>
export default {
  name: "BackToTopButton",
  props: {},
  data: () => {
    return {
      show: false,
    };
  },

  methods: {
    toTop: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll: function () {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 100) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}
.back-to-top-button {
  position: fixed;
  right: 80px;
  bottom: 80px;
  background-color: #b5b5b5;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  transition: opacity 0.5s;
}
.back-to-top-button:hover {
  background-color: #8c8c8c;
}
.back-to-top-img {
  width: 34px;
  height: 34px;
  margin: 3px 0 0 3px;
}
.back-to-top-img path {
  fill: white;
}
</style>
