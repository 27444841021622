<template>
  <div class="top-bar">
    <div class="top-bar-content">
      <div class="top-bar-title"><img class="logo" src="../assets/imgs/logo.png" />和兴信息技术科技有限公司</div>
      <div class="nav">
        <div class="nav-item" :class="{ active: active === 'home' }">首页</div>
        <!-- <div class="nav-item" :class="{ active: active === 'products' }">
          产品中心
        </div>
        <div class="nav-item" :class="{ active: active === 'news' }">
          新闻中心
        </div>
        <div class="nav-item" :class="{ active: active === 'about' }">
          关于我们
        </div> -->
      </div>
      <!-- <div class="contact-btn">联系我们</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  props: {
    active: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar {
  height: 60px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  background: rgb(255, 255, 255);
}
.top-bar-content {
  height: 60px;
  vertical-align: bottom;
  transition: background-image 0.3s, background-color 0.3s ease;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.top-bar-title {
  font-size: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}
.nav {
}
.nav-item {
  display: inline-block;
  color: rgb(43, 43, 43);
  font-size: 14px;
  line-height: 60px;
  text-align: center;
  width: 100px;
  cursor: pointer;
}
.nav-item.active {
  border-top: solid #2b2b2b 2px;
}
.contact-btn {
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  background: #44a8f2;
  width: 135px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logo{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
</style>
