<template>
  <div class="home">
    <TopBar active="home" />
    <Carousel />
    <div class="container-table">
      <!-- <div class="tall-container">
        <img alt="" src="../assets/imgs/home-img1.jpg" />
      </div>
      <div class="tall-container">
        <div class="img-container">
          <img alt="" src="../assets/imgs/home-img2.jpg" />
        </div>
        <div class="img-container">
          <img alt="" src="../assets/imgs/home-img3.jpg" />
        </div>
      </div> -->
      <div class="wide-container">
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img1.jpg" />
        </div>
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img2.jpg" />
        </div>
      </div>
      <div class="wide-container">
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img3.jpg" />
        </div>
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img4.jpg" />
        </div>
      </div>
      <div class="wide-container">
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img5.jpg" />
        </div>
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img6.jpg" />
        </div>
      </div>
      <div class="wide-container">
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img7.jpg" />
        </div>
        <div class="short-img-container">
          <img alt="" src="../assets/imgs/home-img8.jpg" />
        </div>
      </div>
      <!-- <div class="wide-container">
        <img
          alt=""
          src="../assets/imgs/home-img6.jpg"
        />
        <img
          alt=""
          src="../assets/imgs/home-img7.jpg"
        />
        <img
          alt=""
          src="../assets/imgs/home-img8.jpg"
        />
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
export default {
  name: "Home",
  props: {},
  components: {
    TopBar,
    Carousel,
    Footer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-table {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}
.tall-container {
  width: 586;
  height: 660;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
}
.img-container {
  width: 586;
  height: 318px;
}
.wide-container {
  width: 100%;
  margin-top: 30px;
  display: inline-flex;
  justify-content: space-between;
}
.short-img-container {
}
.short-img-container img {
  width: 586px;
  height: 440px;
}
</style>
